body {
  margin: 0;
  padding: 0;
}

#viewer {
  height: 100vh;
}

.cesium-viewer-bottom {
  display: none;
}

@font-face {
  font-family: 'ember';
  src: url('Amazon_Ember_Rg.ttf') format('truetype');
}
