.contact-list-header,
.contact-list-item {
  display: grid;
  padding: 0.25rem;
  grid-template-columns: 6rem 8rem 10rem 8rem 8rem 8rem;
}

.contact-list-header {
  font-weight: bold;
  text-decoration: underline;
  padding-bottom: 0.5rem;
}

.contact-list-item:hover {
  background-color: rgba(154, 205, 50, 0.4);
  border-radius: 10px;
  cursor: pointer;
}
