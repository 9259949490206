.satellite-list-header,
.satellite-list-item {
  display: grid;
  padding: 0.25rem;
  grid-template-columns: 6rem 8rem 6rem 6rem;
}

.satellite-list-header {
  font-weight: bold;
  text-decoration: underline;
  padding-bottom: 0.5rem;
}

.satellite-list-item:hover {
  background-color: rgba(154, 205, 50, 0.4);
  border-radius: 10px;
  cursor: pointer;
}

input[type='color'] {
  border-radius: 100%;
  height: 1rem;
  width: 1rem;
  border: none;
  outline: none;
  background-color: silver;
}

input[type='color']:hover {
  cursor: pointer;
}

input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type='color']::-webkit-color-swatch {
  border: none;
  border-radius: 100%;
}
