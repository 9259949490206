input {
  padding: 0.25rem;
  border-radius: 10px;
  border: 1px solid silver;
  width: 100%;
}

input:focus {
  border: 1px solid yellowgreen;
  border-radius: 10px;
}

.form-wrapper {
  width: 40rem;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.input-wrapper {
  width: 100%;
  padding: 0.5rem;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  border: 1px solid silver;
  border-radius: 10px;
  background-color: yellowgreen;
  padding: 0.5rem;
  opacity: 0.9;
}

.btn:hover {
  opacity: 1;
  cursor: pointer;
}

.btn div {
  display: flex;
  align-items: center;
}
