* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

.menu-container {
  position: absolute;
  width: auto;
  z-index: 1;
  top: 10px;
  left: 10px;
  background-color: rgb(0, 0, 0, 0.4);
  color: white;
  font-family: ember;
  padding: 0.5rem;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.menu-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.header .toggle {
  opacity: 0.8;
  cursor: pointer;
}

.header .toggle:hover {
  opacity: 1;
}

.menu-content .tabs {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  padding-top: 0.5rem;
}

.menu-content {
  max-height: 700px;
  transition: max-height 0.2s ease-out;
  overflow: hidden;
}

.menu-content.closed {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in;
}

.tabs .tab-item {
  display: flex;
  border: 1px solid silver;
  border-bottom: none;
  align-items: center;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  border-radius: 10px 10px 0 0;
  margin-bottom: -1px;
}

.tabs .tab-item.selected {
  color: greenyellow;
  border-bottom: solid 1px rgb(0, 0, 0, 0.8);
}

.tabs .tab-item:hover {
  color: greenyellow;
  cursor: pointer;
}

.tabs .tab-item span {
  padding: 0 0.5rem;
}

.menu-content .menu-body {
  border: 1px solid silver;
  border-radius: 0px 10px 10px 10px;
  padding: 0.5rem;
  max-height: 50vh;
  overflow-y: auto;
}

.spinner {
  border: 0.25rem solid #f3f3f3; /* Light grey */
  border-top: 0.25rem solid #3498db; /* Blue */
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
